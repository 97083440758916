














































import { DataTableHeader } from 'vuetify';
import { ICopyStakeGameItemResponse } from '@/api/schema';

import { toTitleCase } from '@/helpers/formatString';
import { addThumbnailPrefix } from '@/helpers/copyStakeHelpers';

export default {
  props: {
    rows: {
      type: Array,
      default: (): any => []
    },
    hideColumns: {
      type: Array,
      default: (): string[] => []
    },
    serverItemsLength: {
      type: Number,
      default: 0
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableHeaders(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        {
          text: 'Game Code',
          value: 'code'
        },
        {
          text: 'Game Name',
          value: 'name'
        },
        {
          text: 'Game Provider',
          value: 'provider'
        },
        {
          text: 'Date Added (UTC)',
          value: 'whitelistedAt'
        },
        {
          text: 'Game image',
          value: 'image',
          width: 140,
          align: 'center'
        }
      ];

      return headers.filter(
        (column) => !this.hideColumns.includes(column.value)
      );
    }
  },
  methods: {
    toTitleCase,
    addThumbnailPrefix,
    handlerOpenConfirmRemoveModal(): void {
      this.$emit('open-confirm-remove-modal');
    },
    handlerOpenConfirmRemoveAllModal(): void {
      this.$emit('open-confirm-remove-all-modal');
    },
    handlerOpenEditGameImageDialog(
      payload: ICopyStakeGameItemResponse
    ): void {
      this.$emit('open-edit-game-image-dialog', payload);
    },
    handlerOpenGameImagePreviewDialog(
      payload: ICopyStakeGameItemResponse
    ): void {
      this.$emit('open-game-image-preview-dialog', payload);
    }
  }
};
